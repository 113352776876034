:root {
    --z-index-global-grid: 2000;
    --z-index-charging-options: 20;
    --z-index-header: 30;
    --z-index-header-cta: 40;
    --z-index-navbar: 3000;
    --z-index-floater: 8000;
    --z-index-cta-desktop: 20;
    --z-index-skip-link: 1000;
    --z-index-modal: 5000; //for modals not in top layer
    --zindex-spin-navigation: 205;
}
