.content,
.table {
    table {
        width: 100%;
        font-size: 12px;
    }

    thead th {
        font-family: var(--font-semibold);
        border-bottom: 1px solid var(--color-grey-4);
    }

    tbody td {
        padding-block: var(--spacing-1);
        border-bottom: 1px solid var(--color-grey-1);
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
    tr {
        background-color: var(--color-grey-1);
        border-bottom: calc(var(--spacing-3) / 2) solid var(--color-primary-white);
        display: flex;
        flex: 1;
        justify-content: space-between;
    }
    td {
        padding: var(--spacing-2);
        font-size: 1.3rem;
        line-height: 2rem;
        &:last-child:not(:only-child) {
            text-align: right;
            font-family: var(--font-semibold);
        }
    }
}

.checkbox {
    input {
        margin-right: var(--spacing-1);
        accent-color: var(--color-primary-black);
    }
}

.debug-modal {
    fieldset {
        margin-bottom: var(--spacing-2);
    }

    button + p {
        margin-top: var(--spacing-2);
    }
    pre {
        white-space: pre-wrap;
    }
}

.entry {
    margin-bottom: 2rem;

    .entry-header {
        margin-bottom: 1rem;
    }
}

.parent-collapse-summary {
    font-size: 1.5rem;
    padding-block: var(--spacing-1);

    border-bottom: 1px solid var(--color-grey-1);
}
