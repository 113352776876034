@import "../../../../../shared-components/css-modules/toyota/styles/mixins/mixins";
@import "../../../../../shared-components/css-modules/toyota/utils/Animations.module";

.wrapper {
    display: grid;
    grid-row: auto / span 6; /* using subgrid amount of direct children that should be equal on track */
    grid-template-rows: subgrid;
    grid-template-columns: minmax(0px, 1fr);
    scroll-snap-align: start;
    min-inline-size: var(--wrapper-size, 300px);
    max-inline-size: var(--wrapper-size, 300px);
    inline-size: 100%;
    border: 1px solid var(--color-grey-4);
    border-radius: var(--spacing-1);
    padding-block-start: var(--spacing-2);
    @include media-breakpoint-up(sm) {
        --wrapper-size: 380px;
    }

    > * {
        inline-size: 100%;
    }

    &.empty {
        --wrapper-size: 100%;

        position: relative;
        inline-size: 100%;
        max-width: 100%;
        padding-block-start: unset;
        border: 0;
    }
}

.image-section {
    margin-block-start: auto;
}

.section {
    padding: 0 var(--spacing-2);
}

.equipment-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &:not(:first-child) {
        border-block-start: 1px solid var(--color-grey-4);
    }

    li {
        display: grid;
        grid-template-columns: 20px auto;
        gap: var(--spacing-2);
        margin: 0;
        padding: var(--spacing-1) 0;
        border-bottom: 1px solid var(--color-grey-4);

        svg {
            position: relative;
        }

        &:last-child {
            border: 0;
            padding-block-end: var(--spacing-2);
        }
    }
}

.equipment-item-name {
    --spacing: 0;
    text-transform: none !important;
}

.top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: start;
    column-gap: var(--spacing-2);
    margin-block-end: var(--spacing-4);
}

.pre-title {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
    min-block-size: 2.8rem;
    margin-block-end: calc(var(--spacing-3) / 2);
}

.eco-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    min-block-size: 2.8rem;
    span {
        display: inline-flex;
        align-items: center;
    }
    &:not(:last-child):not(:only-of-type)::after {
        content: "";
        background: var(--color-grey-4);
        block-size: 100%;
        inset-inline-end: calc(var(--spacing-2) / -2);
        position: absolute;
        inline-size: 1px;
    }
}

.title {
    display: flex;
    align-items: center;
}

.grlogo {
    inline-size: auto;
    block-size: 16px;
    margin-inline-start: var(--spacing-1);
    line-height: 1;

    svg {
        block-size: 16px;
    }
}

.image {
    display: flex;
    justify-content: center;
    max-inline-size: 100%;
    inline-size: 100%;
    block-size: auto;

    > img {
        max-width: 100%;
        block-size: auto;
    }
}

.colors {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-2);
    list-style: none;
    margin: 0;
    padding: 0;

    .swatch {
        block-size: var(--spacing-3);
        inline-size: var(--spacing-3);
        cursor: pointer;

        &::after {
            block-size: var(--spacing-4);
            inline-size: var(--spacing-4);
        }
    }
}

.price {
    margin-block: var(--spacing-1);
    font-family: var(--font-regular);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--color-primary-black);

    strong {
        display: inline-block;
        margin-block-end: 0;
        font-weight: 400;
        font-family: var(--font-regular);
        color: var(--color-primary-black);
    }
}

.eta {
    margin-block-end: var(--spacing-2);
    font-size: 1.3rem;
    line-height: 2rem;
    text-transform: none;
}

.price + .eta {
    margin-block-start: calc(var(--spacing-1) * -1);
}

div {
    display: block;
}

.button {
    margin-block-start: 0;
    + button {
        --btn-padding: 1.1rem var(--spacing-3);
        margin-block-start: var(--spacing-1);
    }
}

.fade,
.equipment-list {
    opacity: 0;
    animation: fadeIn 0.7s forwards ease-out;
}

.checkmark-item {
    --_color: var(--color-primary-black);
    font-size: 1.4rem;
    line-height: 1.8rem;
}

.features {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.features-list {
    position: relative;
    z-index: 3;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.features-list-category {
    text-transform: none !important;
    padding-block-start: var(--spacing-2);
}

.features-list-item {
    padding-block: var(--spacing-1);
    border-block-end: 1px solid var(--color-grey-4);
    details {
        margin-block-end: 0;
    }
}

.category-group:last-child .features-list-item:last-child {
    border: 0;
}

.collapse {
    block-size: 100%;
}

.collapse > div {
    block-size: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.highlights-view {
    block-size: 100%;
}
