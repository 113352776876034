.filter-modal-header {
    display: flex;
    justify-content: space-between;
    padding-block-end: var(--spacing-2);
    border-block-end: 1px solid var(--color-grey-4);

    button {
        display: flex;
        align-items: center;
        gap: var(--spacing-1);

        svg {
            top: 1px;
        }
    }
}

.filter-modal-content {
    display: flex;
    flex-direction: column;
}

.filter-modal-section {
    padding-block: var(--spacing-2);
}

.filter-button-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
    padding-block-start: var(--spacing-1);

    // Checkbox & Radio specific css
    p {
        font-size: 1.3rem;
    }

    label {
        display: flex;
        align-items: center;
        gap: var(--spacing-1);

        div {
            block-size: var(--spacing-3);
            inline-size: var(--spacing-3);
            aspect-ratio: 1/1;

            &::after {
                block-size: 1rem;
            }
        }
    }
}
