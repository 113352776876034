.textload {
    --color: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
        ),
        var(--color-grey-4);
    inline-size: 100%;
    block-size: 1.5em;
    background: var(--color);
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: -50px 0;
    animation: shine 1.6s ease-in-out infinite;
}

.skeletonimg {
    composes: textload;
    inline-size: 100%;
    block-size: auto;
    aspect-ratio: 16/ 6;
}

@keyframes shine {
    to {
        background-position:
            140% 0,
            0 0;
    }
}
