@import "../../../../../../shared-components/css-modules/toyota/styles/mixins/mixins";

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--spacing-3) / 2);
    align-items: center;
    @include media-breakpoint-down(sm) {
        overflow-x: auto;
        padding-bottom: var(--spacing-1);
    }
}

.active-filters-wrapper {
    @include media-breakpoint-up(md) {
        display: none;
    }

    .active-filters-content {
        display: flex;
        justify-content: space-between;
        margin-inline: auto;
        padding-inline: var(--or-c-padding);
        max-inline-size: var(--or-c-max-width);

        > button {
            display: flex;
            gap: var(--spacing-1);

            & svg {
                top: 1px;
            }
        }
    }

    padding-block-end: var(--spacing-2);
    border-block-end: 1px solid var(--color-grey-4);
}
