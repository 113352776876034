.sr-only:not(:focus):not(:active) {
    position: absolute;
    inline-size: 1px;
    inset-inline-start: 0;
    block-size: 1px;
    padding: 0;
    white-space: nowrap;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
}

.skip-to-content {
    color: var(--color-primary-white, #fff);
    position: absolute;
    inset-inline-start: -10000px;
    inset-block-start: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    text-align: center;
    transition: transform 0.3s;
    background: var(--color-primary-black, #333);
}

.skip-to-content:focus-visible {
    position: static;
    width: auto;
    height: auto;
}
