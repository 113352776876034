@import "../styles/mixins/mixins";
/* Floater styles used for popover and tooltips */

.tooltip {
    --tooltip-text-color: var(--color-grey-1);
    --tooltip-background-color: var(--color-primary-black);
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
    --tooltip-max-width: 280px;

    display: block;
    position: absolute;
    top: 0;
    left: 0; // Don't convert to inline-start because it messes up the tooltip position calculation
    inline-size: max-content;
    z-index: var(--z-index-floater);
}

.default {
    composes: tooltip;

    padding: var(--spacing-3);
    font-family: var(--font-book);
    font-size: 1.3rem;
    line-height: 2rem;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    transform: translate(-50%, -100%);
    text-align: start;
    white-space: normal;
    max-inline-size: calc(300px - 10px); /* ${5 * 2}px) */
    a {
        font-family: var(--font-book);
        color: var(--tooltip-text-color);
        text-decoration: underline;
        &:is(:hover, :active) {
            color: var(--color-primary-white);
        }
    }
    /* Below is some styling that needed to be overruled because of inline styles in tables, also strong gets a default 1.6rem size by external styles */
    strong {
        font-size: 1.2rem;
    }
    table {
        margin-block-end: var(--spacing-1);
        * {
            color: var(--tooltip-text-color) !important;
        }
        tr {
            border-block-end: 1px solid var(--tooltip-text-color);

            td {
                padding-block: calc(var(--spacing-1) / 2);

                &:last-child {
                    text-align: end;
                }
            }
        }
    }
}

.centered {
    composes: default;

    text-align: center;
    img {
        max-inline-size: 120px;
    }
}

.arrow {
    display: block;
    position: absolute;
    inline-size: 8px; /* ${10 - 2}px Account for 1px border on each side */
    block-size: 8px; /* ${10 - 2}px Account for 1px border on each side */
    content: "";
    background-color: var(--tooltip-background-color);
}

.filter-box {
    composes: tooltip;

    --tooltip-text-color: var(--color-primary-black);
    --tooltip-background-color: var(--color-primary-white);

    padding: var(--spacing-3);
    font-family: var(--font-semibold);
    font-size: 1.3rem;
    line-height: 2rem;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    transform: translate(-50%, -100%);
    text-align: start;
    white-space: normal;
    max-inline-size: calc(300px - 10px); /* ${5 * 2}px) */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .arrow {
        display: none;
    }
}

.empty {
    composes: tooltip;
    .arrow {
        display: none;
    }
}

.lcv-filter {
    composes: tooltip;
    max-inline-size: calc(var(--c-max-width) - (var(--c-padding) * 2));
    min-inline-size: 155px;
    .arrow {
        display: none;
    }
    background: var(--color-primary-white);
    padding: var(--spacing-2);
    border: 1px solid var(--color-grey-4);
    box-shadow:
        0px 15px 80px 0px rgba(0, 0, 0, 0.04),
        0px 4.522px 24.118px 0px rgba(0, 0, 0, 0.05),
        0px 1.878px 10.017px 0px rgba(0, 0, 0, 0.03),
        0px 0.679px 3.623px 0px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    text-align: center;
    @include media-breakpoint-down(lg) {
        //library overrules
        position: fixed !important;
        top: 50% !important;
        inset-inline-start: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}

.action-panel {
    composes: tooltip;
    max-inline-size: 100%;
    min-inline-size: 155px;
    .arrow {
        display: none;
    }
    background: var(--color-primary-white);
    padding: var(--spacing-2);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    text-align: center;
    @include media-breakpoint-down(lg) {
        //library overrules
        position: fixed !important;
        top: 50% !important;
        inset-inline-start: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}

.grade-switcher {
    .arrow {
        display: none;
    }

    position: fixed;
    top: 12px;
    bottom: 0;
    inset-inline-start: 0;
    inline-size: 99%;
    inset-inline-end: 0;
    max-block-size: calc(100dvh - var(--toyota-header-height) - var(--header-height));
    background: var(--color-primary-white);
    padding-block: 20px;
    z-index: var(--z-index-floater);
    block-size: calc(100vh - var(--header-height) - var(--toyota-header-height));
    @include media-breakpoint-down(sm) {
        //library overrules
        position: fixed !important;
        inset-block: 0;
        inset-inline: 0;
        inline-size: 100%;
        block-size: 100%;
        max-block-size: none;
        transform: none !important;
    }

    &[data-browser-is-zoomed="true"] {
        max-block-size: none;
    }
}

@supports selector(:has(+ *)) {
    body:has(.grade-switcher) {
        overflow: hidden;
    }
}
.compare-selector {
    background: var(--color-primary-white);
    inset-inline-start: 0;
    inline-size: 100%;
    transform: none !important;
    border: 1px solid var(--color-grey-6);
    border-top: 0;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    box-shadow: var(--dropshadow-md);
    z-index: var(--z-index-floater);

    @include media-breakpoint-down(lg) {
        position: fixed !important;
        inset: 0;
        z-index: calc(var(--z-index-floater) + 5);
    }
}
