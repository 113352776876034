@import "../../../../shared-components/css-modules/toyota/styles/mixins/mixins";

.wrapper {
    padding-block: var(--spacing-10);
}

.slidertitle,
.slidersubtitle {
    font-family: var(--font-book);
    font-size: 2.8rem;
    line-height: 2.6rem;
    font-weight: 300;
    text-align: start;

    strong {
        font-size: inherit;
        line-height: inherit;
        font-family: var(--font-semibold);
    }
}

.slidersubtitle {
    font-size: 2rem;
}

.filters {
    @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: auto minmax(auto, 370px);
        align-content: center;
        gap: var(--spacing-10);
    }
}

.actions {
    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.compare {
    margin-block-start: var(--spacing-3);
    margin-inline-start: auto;

    @include media-breakpoint-up(md) {
        margin-block-start: 0;
    }

    dialog > div {
        display: grid;
        block-size: 100%;
    }
}
