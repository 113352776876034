// Take over the screen reader class from ally because used as a check here.
.sr-only {
    composes: sr-only from "../utils/Accessibility.module.scss";
}

.default {
    --_spacing: var(--spacing, 0);

    position: relative;
    padding-inline-start: 3rem;
    margin-block-end: var(--_spacing);
    label {
        padding: 0;
        margin: 0;
        font-family: var(--font-book);
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 400;
        cursor: pointer;
        &::before {
            position: absolute;
            top: 0.3rem;
            inset-inline-start: 0;
            inline-size: 2rem;
            block-size: 2rem;
            border: 1px solid var(--color-grey-6);
            background-color: var(--color-primary-white);
            border-radius: 0.4rem;
            content: "";
        }
    }
    input[type="checkbox"] {
        position: absolute;
        border: 0px;
        clip: rect(0px, 0px, 0px, 0px);
        block-size: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0px;
        position: fixed;
        white-space: nowrap;
        inline-size: 1px;
        top: 0px;
        inset-inline-start: 0px;

        // Checked State
        &:checked + label {
            &::before {
                background-color: var(--color-primary-black);
                border-color: var(--color-primary-black);
            }
            &::after {
                position: absolute;
                top: 0.9rem;
                inset-inline-start: 0.5rem;
                inline-size: 1rem;
                block-size: 0.8rem;
                background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.36358' y1='4.30268' x2='4.03025' y2='6.96935' stroke='white' stroke-width='1.5'/%3E%3Cline x1='2.96967' y1='6.96967' x2='8.96967' y2='0.96967' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                content: "";
            }
        }
        &:focus-visible + label {
            &::before {
                outline: 2px solid var(--color-electrified-blue) !important;
            }
        }
        // Disabled State
        &:disabled {
            & + label {
                cursor: not-allowed;
                opacity: 0.5;
                &::before {
                    opacity: 0.5;
                }
            }
        }
    }
}
