@import "../styles/mixins/mixins";

@value textload from "../utils/Skeleton.module.scss";

.heading-global {
    --_color: var(--color, inherit);
    --_spacing: var(--spacing, 0);
    --_font-size: var(--font-size, 1.6rem);
    --_line-height: var(--line-height, 2.4rem);

    margin-inline: 0;
    margin-block: 0 var(--_spacing);
    color: var(--_color);
    font-weight: 400;
}

.title-book {
    composes: heading-global;
    --default-spacing: 4rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 3.4rem);
    line-height: var(--line-height, 4rem);
    text-transform: none;
    @include media-breakpoint-up(md) {
        font-size: 4rem;
        line-height: 4.8rem;
        text-transform: uppercase;
    }
}

.header-semibold {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-regular);
    font-size: var(--font-size, 2.8rem);
    line-height: var(--line-height, 3.2rem);
    text-transform: none;
    @include media-breakpoint-up(md) {
        font-family: var(--font-semibold);
        font-size: 3.4rem;
        line-height: 4rem;
        text-transform: uppercase;
    }
}

.header-book {
    composes: heading-global;
    --default-spacing: 4rem;

    font-family: var(--font-regular);
    font-size: var(--font-size, 2.8rem);
    line-height: var(--line-height, 3.2rem);
    text-transform: none;
    @include media-breakpoint-up(md) {
        font-family: var(--font-book);
        font-size: var(--font-size, 3.4rem);
        line-height: var(--line-height, 4rem);
    }
}

.header-regular {
    composes: heading-global;
    --default-spacing: 4rem;

    font-family: var(--font-regular);
    font-size: var(--font-size, 2.2rem);
    line-height: var(--line-height, 2.8rem);
    text-transform: none;
    @include media-breakpoint-up(md) {
        font-size: var(--font-size, 2.8rem);
        line-height: var(--line-height, 3.2rem);
    }
}

.subheader-regular {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-regular);
    font-size: var(--font-size, 2rem);
    line-height: var(--line-height, 2.4rem);
    text-transform: none;
    @include media-breakpoint-up(md) {
        font-size: var(--font-size, 2.2rem);
        line-height: var(--line-height, 2.8rem);
    }
}

.card-subheader-semibold {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-semibold);
    font-size: var(--font-size, 2rem);
    line-height: var(--line-height, 2.8rem);
    text-transform: none;
}

.card-subheader-regular {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-regular);
    font-size: var(--font-size, 2rem);
    line-height: var(--line-height, 2.8rem);
    text-transform: none;
}

.header-body-regular {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-regular);
    font-size: var(--font-size, 1.6rem);
    line-height: var(--line-height, 2.4rem);
    text-transform: none;
}

.body-book {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 1.6rem);
    line-height: var(--line-height, 2.4rem);
    text-transform: none;

    strong {
        font-family: var(--font-semibold);
        font-weight: 400;
        font-size: var(--font-size, 1.6rem);
        line-height: var(--line-height, 2.4rem);
        text-transform: none;
    }
}

.support-header-semibold {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-semibold);
    font-size: var(--font-size, 1.3rem);
    line-height: var(--line-height, 2rem);
    text-transform: uppercase;
}

.support-book {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 1.3rem);
    line-height: var(--line-height, 2rem);
    text-transform: uppercase;
}

.lead {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 1.8rem);
    line-height: var(--line-height, 2.6rem);
    text-transform: none;
}

.hybrid-label {
    composes: heading-global;
    --default-spacing: 0.4rem;
    --_color: var(--color, var(--color-electrified-blue));

    font-family: var(--font-book);
    font-size: var(--font-size, 1.4rem);
    line-height: var(--line-height, 1.8rem);
}

.apheleia-h1 {
    composes: heading-global;
    font-style: normal;
    font-weight: 400;
    font-family: var(--font-book);
    font-size: var(--font-size, 4rem);
    line-height: var(--line-height, 6rem);
    text-transform: none;
}

.apheleia-h2 {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 2rem);
    line-height: var(--line-height, 2.8rem);
    text-transform: none;
    @include media-breakpoint-up(xl) {
        font-size: var(--font-size, 2.8rem);
        line-height: var(--line-height, 3.4rem);
    }
}

.apheleia-h3 {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 2rem);
    line-height: var(--line-height, 2.6rem);
    text-transform: none;
}

.apheleia-h4 {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 1.6rem);
    line-height: var(--line-height, 2.4rem);
    text-transform: none;
}

.apheleia-h5 {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 1.4rem);
    line-height: var(--line-height, 2.2rem);
    text-transform: none;
}

.apheleia-body {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-book);
    font-size: var(--font-size, 1.2rem);
    line-height: var(--line-height, 2.2rem);
    text-transform: none;

    strong {
        margin: 0;
        font-family: var(--font-semibold);
        font-size: var(--font-size, 1.2rem);
        line-height: var(--line-height, 2.2rem);
        font-weight: 400;
        text-transform: none;
    }

    a {
        text-decoration: underline;
    }
}

.apheleia-hybrid-label {
    composes: heading-global;
    --default-spacing: 0.4rem;
    --_color: var(--color, var(--color-electrified-blue));

    font-family: var(--font-semibold);
    font-size: var(--font-size, 1rem);
    line-height: var(--line-height, 2.2rem);
    text-transform: uppercase;
}

.apheleia-price {
    composes: heading-global;
    --default-spacing: 1.6rem;

    font-family: var(--font-regular);
    font-size: var(--font-size, 1.4rem);
    line-height: var(--line-height, 2rem);
    text-transform: none;
    @include media-breakpoint-down(lg) {
        font-size: var(--font-size, 1.2rem);
        line-height: var(--line-height, 2.2rem);
    }
}

.apheleia-border-title {
    composes: heading-global;

    position: relative;
    margin-block: 0 var(--spacing-2);
    padding-block: 0 var(--spacing-2);
    font-family: var(--font-book);
    font-size: var(--font-size, 2.8rem);
    line-height: var(--line-height, 3.4rem);
    text-transform: none;
    &::after {
        position: absolute;
        bottom: 0;
        inset-inline-start: 0;
        block-size: 0.1rem;
        inline-size: 2.6rem;
        background: var(--color-primary-red);
        content: "";
    }
}

.skeleton {
    composes: textload;
    inline-size: 100%;
    block-size: 1em;
}
