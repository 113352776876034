.disclaimers {
    padding: var(--spacing-2);
    padding-inline-end: var(--spacing-3);
    font-size: 1.2rem;
    line-height: 2.2rem;
    background-color: var(--color-grey-1);
}

.disclaimer {
    position: relative;
    padding-inline-start: 3.6rem;

    & + .disclaimer {
        margin-block-start: var(--spacing-1);
    }

    .disclaimer-title {
        font-family: var(--font-semibold);
    }

    a {
        text-decoration: underline;
    }
}

.disclaimer-number {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    inline-size: 2rem;
    block-size: 2rem;
    line-height: 2rem;
    text-align: center;
    background-color: var(--color-grey-4);
    border-radius: 50%;
}
