.icon {
    --_color: var(--color, inherit);
    display: inline-block;
    inline-size: 4rem;
    block-size: 4rem;
    color: var(--_color);
    transition: color var(--default-transition-time) ease-out;
}

[dir="rtl"] {
    .icon-arrow-left,
    .icon-chevron-left,
    .icon-chevron-right {
        rotate: 180deg;
    }
}

.xxs {
    composes: icon;

    inline-size: 1rem;
    block-size: 1rem;
}

.xs {
    composes: icon;

    inline-size: 1.4rem;
    block-size: 1.4rem;
}

.sm {
    composes: icon;

    inline-size: 2rem;
    block-size: 2rem;
}

.md {
    composes: icon;

    inline-size: 2.4rem;
    block-size: 2.4rem;
}

/* For now lg is a placeholder */
.lg {
    composes: icon;

    inline-size: 6rem;
    block-size: 6rem;
}

.xl {
    composes: icon;

    inline-size: 8rem;
    block-size: 8rem;
}
