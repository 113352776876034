@import "../../../../../../shared-components/css-modules/toyota/styles/mixins/mixins";
@value clean from "../../../../../../shared-components/css-modules/toyota/atoms/Button.module.scss";

.clean.filter-button-wrapper {
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    border: 1px solid var(--color-grey-4);
    gap: var(--spacing-1);
    align-items: center;
    block-size: var(--spacing-6);
    padding: 10px var(--spacing-2);
    border-radius: 70px;
    color: var(--color-grey-7);

    svg {
        top: 1px;
        transition: none;
    }

    &.active {
        background-color: var(--color-primary-black);
        border: 1px solid var(--color-primary-black);
        color: var(--color-primary-white);
    }

    &.disabled {
        cursor: default;
    }

    &.isOpen {
        border: 1px solid var(--color-primary-black);
        color: var(--color-primary-black);
    }
    &.filter-button-mobile {
        display: flex;
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.filter-button-content-wrapper {
    border: 1px solid var(--color-grey-4);
    border-radius: var(--spacing-1);
    background-color: var(--color-primary-white);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    width: 343px;
    padding: var(--spacing-2);

    .filter-button-header {
        display: flex;
        justify-content: space-between;
        border-block-end: 1px solid var(--color-grey-4);
        padding-block-end: var(--spacing-2);
        color: var(--color-primary-black);

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: var(--spacing-1);

            svg {
                top: 1px;
            }
        }
    }

    .filter-button-content {
        padding-block-start: var(--spacing-2);

        > div {
            padding-block-end: 1rem;
        }

        label {
            inline-size: 100%;
        }

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.3rem;
            color: var(--color-primary-black);
        }

        .swatch {
            label {
                display: flex;
                align-items: center;
                gap: 1rem;

                &::before,
                &::after {
                    top: unset !important;
                }
            }

            div {
                block-size: var(--spacing-3);
                min-inline-size: var(--spacing-3);
                inline-size: var(--spacing-3);
                aspect-ratio: 1/1;

                &::after {
                    block-size: 1rem;
                }
            }
        }
    }
}
