/* Use px for default spacing since they shouldn't have to increase when increasing base font-size */
:root {
    --spacing-0: 0;
    --spacing-1: 8px;
    --spacing-2: 16px;
    --spacing-3: 24px;
    --spacing-4: 32px;
    --spacing-5: 40px;
    --spacing-6: 48px;
    --spacing-7: 56px;
    --spacing-8: 64px;
    --spacing-9: 72px;
    --spacing-10: 80px;
    --spacing-11: 120px;

    --base-mobile-header-h: 70px;
}
