@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes fadeOutSlideDown {
    0% {
        transform: translateX(0);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes slideInBottom {
    to {
        transform: translateY(0);
    }
}

@keyframes slideOutBottom {
    to {
        transform: translateY(100%);
    }
}

@keyframes slideInLeft {
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutLeft {
    to {
        transform: translateX(-100%);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes generalWidth {
    from {
        inline-size: 0;
    }
    to {
        inline-size: 100%;
    }
}
