a,
button {
    outline: 2px solid transparent !important;
    outline-offset: 8px;
    &:focus-visible {
        outline: 2px solid var(--color-electrified-blue) !important;
        outline-offset: 2px;
        @media (prefers-reduced-motion: no-preference) {
            transition:
                outline 0.3s ease-out,
                outline-offset 0.3s ease-out;
        }
    }
    @supports not selector(:focus-visible) {
        &:focus-within {
            outline: 2px solid var(--color-electrified-blue) !important;
            outline-offset: 2px;
            @media (prefers-reduced-motion: no-preference) {
                transition:
                    outline 0.3s ease-out,
                    outline-offset 0.3s ease-out;
            }
        }
    }
}
