.incentive {
    display: inline-flex;
    gap: calc(var(--spacing-1) / 2);
    align-items: center;
    font-family: var(--font-book);
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-feature-settings:
        "liga" off,
        "clig" off;
}

.best-seller {
    composes: incentive;
    color: #357a38;
}

.efficient {
    composes: incentive;
    color: var(--color-primary-black);
}

.icon {
    top: 0.1rem;
}
