@value textload from "../utils/Skeleton.module.scss";

.btn-default {
    --btn-padding: calc(var(--spacing-2) - 1px) var(--spacing-3);
    --btn-background: var(--color-primary-black);
    --btn-color: var(--color-primary-white);
    --btn-font: var(--font-book);
    --btn-font-size: 1.6rem;
    --btn-line-height: 2.4rem;
    --btn-font-weight: 400;
    --btn-border: var(--btn-background);

    --btn-hover-color: var(--color-primary-white);
    --btn-hover-background: var(--color-grey-7);
    --btn-hover-border: var(--btn-hover-background);
    --btn-active-color: var(--btn-color);
    --btn-active-background: var(--color-highlight-black);
    --btn-disabled-background: var(--color-grey-4);

    --btn-transition: color var(--default-transition-time) ease-out,
        background-color var(--default-transition-time) ease-out;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: var(--spacing-2);
    padding: var(--btn-padding);
    font-size: var(--btn-font-size);
    line-height: var(--btn-font-size);
    color: var(--btn-color);
    background: var(--btn-background);
    border: 1px solid var(--btn-border);
    font-weight: var(--btn-font-weight);
    cursor: pointer;
    transition: var(--btn-transition);

    &:is(:hover, :focus-visible) {
        color: var(--btn-hover-color);
        background: var(--btn-hover-background);
        border-color: var(--btn-hover-border);
    }

    &:is(:active) {
        color: var(--btn-active-color);
        background: var(--btn-active-background);
    }

    &:is([disabled]) {
        color: var(--color-grey-6);
        background: var(--btn-disabled-background);
        border-color: var(--btn-disabled-background);
        cursor: not-allowed;
    }

    :global(.professional-theme) & {
        --btn-background: var(--color-primary-white);
        --btn-color: var(--color-primary-black);
        --btn-border: var(--color-primary-white);
        --btn-hover-color: var(--color-primary-black);
        --btn-hover-background: var(--color-grey-4);
        --btn-active-color: var(--color-primary-black);
        --btn-active-background: var(--color-grey-1);

        color: var(--btn-color);

        &:is([disabled]) {
            --btn-border: var(--color-grey-7);
            --btn-color: var(--color-grey-6);
        }
    }

    :global(.bz) &:not(.btn-secondary, .btn-arrow) {
        --btn-background: var(--color-bz4x);
        --btn-color: var(--color-primary-white);
        --btn-border: var(--color-bz4x);
        --btn-hover-background: var(--color-bz4x-hover);
        --btn-hover-border: rgba(0, 84, 101, 0.8);

        color: var(--btn-color);

        &:is([disabled]) {
            --btn-border: var(--color-grey-7);
            --btn-color: var(--color-grey-6);
        }
    }

    svg {
        position: relative;
        top: 0.2rem; //Line-height fix of font
        color: inherit;
    }
    > :global(.ellipsis) {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.btn-secondary {
    composes: btn-default;

    --btn-background: transparent;
    --btn-color: var(--color-primary-black);
    --btn-border: var(--color-primary-black);
    --btn-hover-border: var(--color-primary-black);
    --btn-active-color: var(--color-primary-white);

    &:is([disabled]) {
        --btn-border: var(--color-grey-6);
        --btn-color: var(--color-grey-6);
        background: transparent;
    }

    :global(.professional-theme) & {
        --btn-background: transparent;
        --btn-color: var(--color-primary-white);
        --btn-border: var(--color-primary-white);
        --btn-hover-color: var(--color-primary-white);
        --btn-hover-background: var(--color-grey-7);
        --btn-hover-border: var(--color-primary-white);
        --btn-active-color: var(--color-primary-black);
        --btn-active-background: var(--color-primary-white);

        &:is([disabled]) {
            --btn-border: var(--color-grey-7);
            --btn-color: var(--color-grey-7);
        }
    }
}

.btn-arrow {
    composes: btn-default;

    transition:
        border-color var(--default-transition-time) ease-out,
        background-color var(--default-transition-time) ease-out;
    &:is([disabled]) {
        --btn-border: var(--color-grey-4);
        --btn-color: var(--color-grey-6);
        background: var(--color-grey-4);
    }
}

.btn-icon {
    composes: btn-default;

    --btn-background: var(--color-grey-4);
    --btn-color: var(--color-primary-black);
    --btn-border: var(--color-grey-4);
    --btn-hover-border: var(--color-grey-6);
    --btn-hover-background: var(--color-grey-6);
    --btn-hover-color: var(--color-primary-black);
    --btn-active-color: var(--color-primary-black);
    --btn-active-background: var(--color-grey-7);

    transition:
        border-color var(--default-transition-time) ease-out,
        background-color var(--default-transition-time) ease-out;
    &:is([disabled]) {
        --btn-border: var(--color-grey-4);
        --btn-color: var(--color-grey-6);
        background: var(--color-grey-4);
    }
}

.btn-icon-dark {
    composes: btn-default;

    --btn-background: var(--color-primary-black);
    --btn-color: var(--color-primary-white);
    --btn-border: var(--color-primary-black);
    --btn-hover-border: var(--color-grey-7);
    --btn-hover-background: var(--color-grey-7);
    --btn-hover-color: var(--color-primary-white);
    --btn-active-color: var(--color-primary-white);
    --btn-active-background: var(--color-primary-black);

    transition:
        border-color var(--default-transition-time) ease-out,
        background-color var(--default-transition-time) ease-out;
    &:is([disabled]) {
        --btn-border: var(--color-grey-4);
        --btn-color: var(--color-primary-white);
        background: var(--color-grey-4);
    }
}

.btn-text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-1);
    color: var(--color-primary-black);
    background: transparent;
    border: none;

    &:is(:hover) {
        color: var(--color-grey-7);
    }

    &:is(:active) {
        color: var(--color-highlight-black);
    }

    &:is([disabled]) {
        color: var(--color-grey-6);
    }

    svg {
        position: relative;
        top: 0.2rem;
        color: inherit;
    }

    :global(.professional-theme) & {
        color: var(--color-primary-white);
    }
}

.btn-icon-text {
    /* !Importants are necessary to override base Toyota styles */
    --_button-border-width: var(--button-border-width, 1px);
    --_button-border-color: var(--button-border-color, var(--color-primary-black));
    --_button-border-color-hover: var(--button-border-color-hover, var(--color-highlight-black));

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
    padding-block: 0 calc(var(--spacing-1) / 2);
    padding-inline: 0;
    font-family: var(--font-book);
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: var(--color-primary-black);
    background: transparent;
    border-bottom: var(--_button-border-width) solid var(--_button-border-color) !important;

    &:is(:hover, :active) {
        color: var(--color-highlight-black);
        border-bottom-color: var(--_button-border-color-hover) !important;
    }

    &.lg {
        --button-border-width: 0;

        gap: var(--spacing-1);
        padding-block: 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}

.apheleia-text {
    --color: var(--color-primary-black);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: start;
    gap: var(--spacing-1);
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.5;
    color: var(--color);
    border: none;
    text-decoration: underline;
    text-underline-offset: 4px;
    background: transparent;
    transition:
        border-color var(--default-transition-time),
        color var(--default-transition-time);
    &:is(:hover) {
        --color: var(--color-grey-7);
    }

    &:is(:active) {
        --color: var(--color-highlight-black);
    }

    &:is([disabled]) {
        --color: var(--color-grey-6);
    }
    &.white {
        --color: var(--color-primary-white);
    }

    &.lg {
        font-size: 1.6rem;
    }

    svg {
        position: relative;
        top: 0.2rem;
        color: inherit;
    }

    :global(.professional-theme) & {
        --color: var(--color-primary-white);
    }
}

.circle {
    justify-content: center;
    inline-size: 4rem;
    min-inline-size: 4rem;
    block-size: 4rem;
    padding: 0;
    border-radius: 50%;
    &.xs {
        inline-size: 2.4rem;
        min-inline-size: 2.4rem;
        block-size: 2.4rem;
    }
    &.sm {
        inline-size: 3.2rem;
        min-inline-size: 3.2rem;
        block-size: 3.2rem;
    }
    &.lg {
        inline-size: 7.7rem;
        min-inline-size: 7.7rem;
        block-size: 7.7rem;
    }

    svg {
        top: 0;
    }
}

.full-width {
    display: flex;
    inline-size: 100%;
}

.sm {
    --btn-padding: 2px;

    font-size: 10px;
}

.pill {
    --btn-padding: 6px calc(var(--spacing-3) / 2) var(--spacing-1);

    gap: calc(var(--spacing-1) / 2);
    font-size: 1.3rem;
    line-height: 2rem;
    border-radius: 4.2rem;
    svg {
        position: relative;
        top: 0;
        transition: none;
    }
}

.clean {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background: var(--btn-background, none);
    color: inherit;
    border: var(--btn-border, 0);
    transition: var(--btn-transition);
    svg {
        position: relative;
        top: -2px;
    }

    &:is([disabled]) {
        color: var(--color-grey-6);
    }
}

.swatch {
    composes: clean;
    --ring-color: transparent;
    &:is(:hover, :focus, :active) {
        --ring-color: var(--color-primary-black);
    }
    &:is(:focus-visible) {
        --ring-color: var(--color-electrified-blue);

        outline: 4px solid var(--ring-color) !important;
        border-radius: 50%;
        outline-offset: 4px;
    }
}

.btn-toggle {
    composes: clean;

    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-1);
    font-size: 1.4rem;
    :global(.btn-toggle-icon) {
        display: flex;
        justify-content: center;
        align-items: center;
        inline-size: 4rem;
        block-size: 4rem;
        border-radius: 50%;
        background-color: var(--color-primary-black);
        color: var(--color-primary-white);
        transition: background-color var(--default-transition-time) ease-out;
    }
    &:is(:hover, :focus-visible) {
        :global(.btn-toggle-icon) {
            background-color: var(--color-grey-7);
        }
    }
    svg {
        top: 0;
        inline-size: 2rem;
        block-size: 2rem;
        color: var(--color-primary-white);
    }
}

.btn-border-bottom {
    composes: btn-default;

    display: inline-flex;
    padding-block: 0 var(--spacing-1);
    padding-inline: 0;
    gap: var(--spacing-1);
    justify-content: space-between;
    font-size: 1.3rem;
    line-height: 2rem;
    background: transparent;
    color: var(--color-primary-black);
    border-width: 0 0 1px 0;
    &:is(:hover, :active, :focus-visible) {
        background: transparent;
        color: var(--color-highlight-black);
    }
}

.skeleton {
    composes: textload;
    min-inline-size: 90px;
    inline-size: auto;
    block-size: 2em;
    &:is([disabled]) {
        background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 80%
            ),
            var(--color-grey-4);
        background-repeat: repeat-y;
        background-size: 50px 500px;
        background-position: -50px 0;
    }
}
