.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--spacing-1);
    align-items: center;
    padding-inline-start: var(--spacing-2);
    padding-block: var(--spacing-1);
    border-block-start: 1px solid var(--color-grey-4);

    &:not(.summary) {
        border: 1px solid var(--color-grey-4);
        padding-inline-end: var(--spacing-2);
        background-color: var(--color-primary-white);
        cursor: pointer;

        &:first-child {
            margin-block-start: 1px; // In order to have the border of the summary visible.
        }

        &:last-child {
            border-block-start: 0;
        }
    }

    &.active {
        background-color: var(--color-grey-1);
    }

    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    inline-size: 100%;
}

.specs {
    text-align: start;
    inline-size: 100%;
}

.text {
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.summary {
    border: none;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    block-size: var(--spacing-3);
    inline-size: var(--spacing-3);
    background-color: var(--color-primary-white);
    border-radius: 50%;
}

.selected {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: var(--spacing-1);
    width: 100%;
    margin-block: var(--spacing-2) var(--spacing-1);
    padding: calc(var(--spacing-3) / 2) var(--spacing-2);
    font-size: 1.3rem;
    background-color: var(--color-primary-white);
    .selectedIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        border-radius: 50%;
        aspect-ratio: 1;
        border: 1px solid var(--color-primary-black);
    }
}

.selectEngine {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-block: var(--spacing-2) var(--spacing-1);
    padding: calc(var(--spacing-3) / 2) var(--spacing-2);
}
