@import "../styles/mixins/mixins";

:root {
    --c-max-width: 100%;
    --c-padding: var(--spacing-2);
    @include media-breakpoint-up(sm) {
        --c-max-width: 540px;
    }
    @include media-breakpoint-up(md) {
        --c-max-width: 720px;
    }
    @include media-breakpoint-up(lg) {
        --c-max-width: 960px;
        --c-padding: var(--spacing-3);
    }
    @include media-breakpoint-up(xl) {
        --c-max-width: 1140px;
    }
    @include media-breakpoint-up(xxl) {
        --c-max-width: 1480px;
        --c-padding: var(--spacing-5);
    }
}

.default {
    inline-size: 100%;
    max-inline-size: var(--c-max-width);
    padding-inline: var(--c-padding);
    margin-inline: auto;
}

.full-width {
    max-inline-size: 100%;
}
