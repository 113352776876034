@import "../../../../shared-components/css-modules/toyota/styles/mixins/mixins";
@value clean from "../../../../shared-components/css-modules/toyota/atoms/Button.module.scss";
@value btn-default from "../../../../shared-components/css-modules/toyota/atoms/Button.module.scss";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block-end: var(--spacing-2);
    border-bottom: 1px solid var(--color-grey-4);
}

@include media-breakpoint-up(md) {
    .mobile-filter-controls {
        display: none;
    }
}

.header-actions {
    display: flex;
    gap: var(--spacing-2);
}

.filters {
    display: flex;
    flex-wrap: wrap;
}

.filter-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.filter-item {
    display: flex;
    flex-direction: column;
    flex: 25%;
    flex-grow: 0;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-2);
    padding: var(--spacing-2);

    @include media-breakpoint-up(lg) {
        &:has(.filter-item-options li:nth-child(4)) {
            flex: 100%;

            .filter-item-options {
                width: 100%;
                columns: 2;
                column-gap: calc(var(--spacing-2) * 2);
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        &:has(.filter-item-options li:nth-child(4)) {
            flex: 100%;

            .filter-item-options {
                width: 100%;
                columns: 4;
                column-gap: calc(var(--spacing-2) * 2);
            }
        }
    }

    &.full {
        flex: 100%;
    }
}

.filter-item-options {
    margin: 0;
    padding: 0;
    list-style: none;
}

.filter-item-option {
    margin: 0;
    padding-block: calc(var(--spacing-1) / 2);

    &:not(:nth-last-child(1)) {
        margin-block-end: calc(var(--spacing-1) / 2);
    }
}

.filter-item-header {
    display: flex;
    justify-content: space-between;
    align-self: stretch;
}

.radio-wrapper {
    display: flex;
    position: relative;

    label {
        display: inline-flex;
        align-items: center;
        gap: var(--spacing-1);
        position: relative;
        margin: 0;
        padding-inline-start: 36px; /* 20 (width checkbox) + 16 (spacing) */
        font-family: var(--font-book);
        font-size: 1.6rem;
        line-height: 1.5;
        text-align: start;
        color: var(--color-primary-black);
        cursor: pointer;

        &::before {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            inset-inline-start: 0;
            width: 20px;
            height: 20px;
            content: "";
            border: 1px solid var(--color-grey-6);
            border-radius: 50%;
            cursor: pointer;
            overflow: hidden;
        }

        &.reverse {
            flex-direction: row-reverse;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.3rem;
        }
    }

    input[type="radio"] {
        position: absolute;
        opacity: 0;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
    }

    input:checked + label::before {
        background-color: var(--color-primary-white);
        border-color: var(--color-primary-black);
    }

    input:checked + label::after {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        inset-inline-start: 4px;
        width: 12px;
        height: 12px;
        content: "";
        background-color: var(--color-primary-black);
        border-radius: 50%;
    }

    input:disabled + label {
        color: var(--color-grey-6);
        cursor: default;
        &::before {
            background-color: var(--color-primary-white);
            border-color: var(--color-grey-4);
            cursor: default;
        }
    }

    &:hover {
        label::before {
            background-color: var(--color-grey-6);
        }
    }

    &:focus-within {
        label::before {
            outline: 2px solid var(--color-electrified-blue);
            outline-offset: 2px;
        }
    }
}

.btn-default.filter-button,
.clean.filter-button {
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.btn-default.filter-button {
    block-size: var(--or-spacing-6);
    padding: 10px var(--or-spacing-2);
    font-size: 1.6rem;
}

.clean.filter-button {
    border: 1px solid var(--color-grey-4);
    gap: var(--spacing-1);
    align-items: center;
    block-size: var(--spacing-6);
    padding: 10px var(--spacing-2);
    font-size: 1.6rem;
    border-radius: 70px;
    color: var(--color-grey-7);

    svg {
        top: 1px;
        transition: none;
    }

    &.active {
        background-color: var(--color-primary-black);
        border: 1px solid var(--color-primary-black);
        color: var(--color-primary-white);
    }

    &.disabled {
        cursor: default;
    }

    &.isOpen {
        border: 1px solid var(--color-primary-black);
        color: var(--color-primary-black);
    }

    &.mobile {
        display: flex;
        span {
            margin-top: -2px;
        }
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.content {
    display: none;
    @include media-breakpoint-up(md) {
        display: inherit;
    }
}
