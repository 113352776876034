.info-section-wrapper {
    display: flex;
    flex-direction: column;
    block-size: 100%;
}

.category-group {
    border-block-start: 1px solid var(--color-grey-4);
    margin-block-end: 0 !important;

    > summary {
        padding: var(--spacing-2);
    }

    > div {
        padding: 0;
        background-color: var(--color-grey-1);
    }
}

.first-category-group {
    block-size: 100%;
    // SVG's with transform in subcomponents get put on a different stack.
    // A z-index on this wrapper prevents those SVG's from being displayed on a higher level.
    z-index: 100;

    .category-header {
        padding: var(--spacing-2);
    }

    > div {
        padding-inline: var(--spacing-2);
        background-color: unset;
    }
}

.category-group .category-header {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing-1) / 2);

    svg {
        margin-block-start: 1px;
    }
}

.features-list-category {
    display: flex;
    flex-direction: column;
    padding-inline: var(--spacing-2);

    > div {
        margin-block-end: var(--spacing-2);
        padding-block-start: unset;
    }
}

.features-list-item {
    padding-inline: var(--spacing-5) var(--spacing-2);

    details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-block-end: unset;
        border-block-end: 1px solid var(--color-grey-4);

        > summary {
            align-self: stretch;
            padding-block: calc(var(--spacing-3) / 2);
        }

        > div {
            padding: unset;
        }
    }
}

.features-list-item:last-child {
    details {
        border: 0;
    }
}
