:root {
    --color-primary-black: #282830;
    --color-highlight-black: #15151b;
    --color-primary-white: #ffffff;

    --color-primary-red: #ff0022;
    --color-highlight-red: #d40605;

    --color-electrified-blue: #0072f0;
    --color-green: #4caf50;

    --color-bz4x: #003641;
    --color-bz4x-hover: #46a4b0;

    --color-kinto: #00708d;

    --color-grey-1: #f5f5f5;
    --color-grey-4: #e4e4e4;
    --color-grey-6: #a8aaac;
    --color-grey-7: #6c7073;

    --color-eco-a: #33a357;
    --color-eco-b: #79b752;
    --color-eco-c: #c3d545;
    --color-eco-d: #fff12c;
    --color-eco-e: #edb731;
    --color-eco-f: #d66f2c;
    --color-eco-g: #cc232a;
    --color-eco-default: #020202;
}
