// prettier-ignore
.spacing-0 { --spacing: var(--spacing-0); }
// prettier-ignore
.spacing-1 { --spacing: var(--spacing-1); }
// prettier-ignore
.spacing-2 { --spacing: var(--spacing-2); }
// prettier-ignore
.spacing-3 { --spacing: var(--spacing-3); }
// prettier-ignore
.spacing-4 { --spacing: var(--spacing-4); }
// prettier-ignore
.spacing-5 { --spacing: var(--spacing-5); }
// prettier-ignore
.spacing-6 { --spacing: var(--spacing-6); }
// prettier-ignore
.spacing-7 { --spacing: var(--spacing-7); }
// prettier-ignore
.spacing-8 { --spacing: var(--spacing-8); }
// prettier-ignore
.spacing-9 { --spacing: var(--spacing-9); }
// prettier-ignore
.spacing-10 { --spacing: var(--spacing-10); }
// prettier-ignore
.spacing-11 { --spacing: var(--spacing-11); }
