@import "../styles/mixins/mixins";
@import "../utils/Animations.module";

.sidepanel {
    --side-padding-x: var(--spacing-2);
    --side-padding-y: var(--spacing-2);
    --timing-function: cubic-bezier(0.33, 0, 0.67, 1);

    margin: 0;
    border: 0;
    background: var(--color-primary-white);
    @supports (transition-behavior: allow-discrete) {
        transition-property: opacity, translate, overlay, display;
        transition-duration: 0.5s;
        transition-timing-function: var(--timing-function);
        transition-behavior: allow-discrete;
    }

    &::backdrop,
    + .backdrop {
        opacity: 0;
        background-color: rgba(40, 40, 48, 0.5);
        @supports (transition-behavior: allow-discrete) {
            transition-property: opacity, overlay, display;
            transition-duration: 0.4s;
            transition-timing-function: var(--timing-function);
            transition-behavior: allow-discrete;
        }
    }

    &[open]::backdrop,
    &[open] + .backdrop {
        opacity: 0.8;
        pointer-events: none;
        @supports (transition-behavior: allow-discrete) {
            @starting-style {
                opacity: 0;
            }
        }
    }

    @media (hover: hover) {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px var(--color-grey-1);
            background-color: var(--color-primary-white);
        }

        &::-webkit-scrollbar {
            inline-size: 5px;
            background-color: var(--color-primary-white);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-grey-4);
        }
    }

    &.out-of-toplayer {
        // Specifically so sidepanels are hidden by the backdrop from out-of-toplayer modals
        --z-index-modal: 1049;
        position: fixed;
        z-index: var(--z-index-modal);
        overflow-y: auto;

        &:not(.closing).fullscreen {
            inset-block: 0;
        }

        &:not(.fullscreen)[open] + .backdrop {
            // Added because compiled module doesn't take parent defined --z-index-modal value
            --z-index-modal: 1049;
            position: fixed;
            inset-block: 0;
            inset-inline: 0;
            z-index: calc(var(--z-index-modal) - 1);
            content: "";
        }
    }
}

.default {
    composes: sidepanel;

    --side-padding-y: 0;

    min-inline-size: min(1000px, 100%);
    inline-size: 100%;
    max-inline-size: 1000px;
    min-block-size: 100vh;
    block-size: 100vh;
    padding: 0;

    &.no-title {
        --side-padding-y: var(--spacing-2);
    }

    @include media-breakpoint-up(md) {
        --side-padding-x: var(--spacing-5);
    }
    @include media-breakpoint-up(xl) {
        /* Note for future devs: the 1000px is a maximum based on the size of image assets we can get (packs and extras), do not just change! */

        --width: calc(50vw - (var(--c-max-width) / 100 * 14));

        min-inline-size: min(1000px, var(--width));
        inline-size: var(--width);
        max-inline-size: 1000px;
    }

    .close {
        position: sticky;
        float: inline-end;
        inline-size: 4rem;
        block-size: 4rem;
        inset-inline-end: var(--side-padding-x);
        top: var(--side-padding-y);
    }

    .header {
        position: sticky;
        top: 0;
        inset-inline-start: 0;
        padding-block: var(--spacing-2);
        background: var(--color-primary-white);
        z-index: 5;
    }
}

.content {
    min-block-size: 100%;
    padding-block: var(--side-padding-y);
    padding-inline: var(--side-padding-x);
}

.header-no-title {
    display: flex;
    position: sticky;
    inset-block-start: 0;
    justify-content: flex-end;
    align-items: start;
    padding-block-end: var(--spacing-2);
    z-index: 1000;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: var(--spacing-3);
    margin-block-end: var(--spacing-2);
}

.title {
    --spacing: 0;
}

@supports selector(:has(+ *)) {
    body:has(.sidepanel:not(.summary)[open]) {
        overflow-y: hidden;
    }
}

/* half screen with image variant */

.split-panel {
    composes: default;

    .split-panel-image {
        display: none;
    }

    @include media-breakpoint-up(xl) {
        min-inline-size: 100%;
        inline-size: 100%;
        overflow: hidden;
        background: transparent;

        .content {
            --transition: translate 0.4s ease-out;
            --header-height: 64px;
            --side-padding-x: 0;

            > * {
                inline-size: 50%;
                margin: 0;
                padding-block: var(--spacing-2);
                padding-inline: var(--spacing-5);
            }
        }
        .header,
        .split-panel-text {
            translate: -100% 0;
            transition: var(--transition);

            [dir="rtl"] & {
                translate: 100% 0;
            }
        }

        .split-panel-text {
            background: var(--color-primary-white);
            overflow-y: auto;
            height: calc(100vh - var(--header-height));

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px var(--color-grey-1);
                background-color: var(--color-primary-white);
            }

            &::-webkit-scrollbar {
                inline-size: 5px;
                background-color: var(--color-primary-white);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--color-grey-4);
            }
        }

        .split-panel-image {
            position: fixed;
            display: block;
            inset-block: 0;
            inset-inline: 50% 0;
            padding: 0;
            height: 100vh;
            translate: 0 100%;
            transition: var(--transition);

            &::after {
                content: "";
                position: absolute;
                inset: 0;
                transition: opacity 0.4s;
                background-color: rgba(40, 40, 48, 0.5);
                opacity: 0.8;
            }

            img {
                inline-size: 100%;
                block-size: 100%;
                object-fit: cover;
            }
        }

        &[open] {
            .split-panel-text,
            .header {
                translate: 0 0;
                @supports (transition-behavior: allow-discrete) {
                    @starting-style {
                        translate: -100% 0;

                        [dir="rtl"] & {
                            translate: 100% 0;
                        }
                    }
                }
            }

            .split-panel-image {
                translate: 0 0;
                @supports (transition-behavior: allow-discrete) {
                    @starting-style {
                        translate: 0 100%;
                    }
                }
            }
        }
    }
}

/* Full screen variant */

.fullscreen {
    composes: sidepanel;

    --side-padding-x: 0;

    min-inline-size: 100%;
    inline-size: 100%;
    min-block-size: 100vh;
    block-size: 100vh;
    @include media-breakpoint-up(md) {
        --side-padding-y: var(--spacing-3);
    }
    @include media-breakpoint-up(xl) {
        --side-padding-y: var(--spacing-5);
    }

    .header {
        max-inline-size: var(--c-max-width);
        padding-inline: var(--c-padding);
        margin-inline: auto;

        button {
            position: relative;
            inset-inline-end: -10px;
        }
    }

    @media (hover: hover) {
        &::-webkit-scrollbar {
            block-size: 5px;
        }
    }
}

/* Summary special case */

.summary {
    composes: default;
    top: 0;
    block-size: 100%;

    .header {
        margin-block-end: 0;
        padding: var(--spacing-2);
        @include media-breakpoint-up(lg) {
            padding: var(--spacing-4);
        }
    }

    .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .content {
        --side-padding-y: 0;
        --side-padding-x: 0;
    }

    &.out-of-toplayer {
        overflow-y: unset;
        z-index: var(--z-index-modal);
    }

    &.no-title {
        --side-padding-y: var(--spacing-3);
        @include media-breakpoint-up(md) {
            --side-padding-y: var(--spacing-5);
        }
    }

    @include media-breakpoint-up(md) {
        --side-padding-x: var(--spacing-4);
    }
    @include media-breakpoint-up(xl) {
        top: calc(var(--header-height) + var(--toyota-header-height, 0) + var(--back-bar-height) - 1px);
        block-size: calc(var(--view-height) - var(--stand-alone-override, 0px) - 200px + var(--spacing-3));
        :global(.header-hidden) & {
            top: calc(var(--header-height) + var(--back-bar-height) - 1px);
        }
        [data-step="highlights"] &,
        [data-step="specs"] & {
            inset-inline-end: 0;
        }
        &::backdrop,
        + .backdrop {
            display: none;
        }
        &.out-of-toplayer {
            overflow-y: unset;
        }
        &.offset {
            inset-inline-end: var(--spacing-5);
        }
    }
}

@include media-breakpoint-down(lg) {
    :global(body.or-apheleia-summary-open) {
        overflow: hidden;
    }
}

// Still used for small changes, although not a fixed variant
.cross-compare {
    padding: 0;
    overflow: hidden;

    .close {
        position: absolute;
        inset-block-start: calc(var(--spacing-2) + 4px);
        inset-inline-end: calc(((100% - var(--c-max-width) + (var(--c-padding) * 2)) / 2));
    }

    .content {
        position: relative;
        max-height: 100vh;
        padding-block-start: var(--spacing-2);
        overflow: auto;
    }
}

/* Animations -> slide in directions */
.inline-start {
    inset-inline: 0 auto;
    translate: -100% 0;

    [dir="rtl"] & {
        translate: 100% 0;
    }

    &[open] {
        translate: 0 0;
        @supports (transition-behavior: allow-discrete) {
            @starting-style {
                translate: -100% 0;
                [dir="rtl"] & {
                    translate: 100% 0;
                }
            }
        }
    }
}

.inline-end {
    inset-inline: auto 0;
    translate: 100% 0;

    [dir="rtl"] & {
        translate: -100% 0;
    }

    &[open] {
        translate: 0 0;
        @supports (transition-behavior: allow-discrete) {
            @starting-style {
                translate: 100% 0;
                [dir="rtl"] & {
                    translate: -100% 0;
                }
            }
        }
    }
}

.block-start {
    opacity: 0;
    translate: 0 -100%;

    &[open] {
        opacity: 1;
        translate: 0 0;
        @supports (transition-behavior: allow-discrete) {
            @starting-style {
                translate: 0 -100%;
            }
        }
    }
}

.block-end {
    opacity: 0;
    translate: 0 100%;

    &[open] {
        opacity: 1;
        translate: 0 0;
        @supports (transition-behavior: allow-discrete) {
            @starting-style {
                translate: 0 100%;
            }
        }
    }
}

.none,
.mobile-none {
    inset-inline: 0 auto;
    translate: 0 0;
}

/* mobile overwrites */

@include media-breakpoint-down(lg) {
    :global(.or-apheleia-summary-open .header) {
        z-index: -1;
    }
    :global(.or-apheleia-summary-open) {
        overflow-y: hidden;
    }
    .mobile-block-end {
        opacity: 0;
        translate: 0 100%;

        &[open] {
            opacity: 1;
            translate: 0 0;
            @supports (transition-behavior: allow-discrete) {
                @starting-style {
                    translate: 0 100%;
                }
            }
        }
    }
    .mobile-block-start {
        opacity: 0;
        translate: 0 -100%;

        &[open] {
            opacity: 1;
            translate: 0 0;
            @supports (transition-behavior: allow-discrete) {
                @starting-style {
                    translate: 0 -100%;
                }
            }
        }
    }
}

.injection-content {
    margin: calc(var(--spacing-2) * -1);

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-inline-size: 100%;
        inline-size: 100%;
        margin: 0;
        scroll-padding-block-start: 40px; /* 40px = height button in header */
        overflow: clip;
    }
}
