.details {
    margin-block-end: var(--spacing-3);
    summary {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        cursor: pointer;
        list-style-type: none;
        &::-webkit-details-marker {
            display: none;
        }
    }
}

.content {
    padding: var(--spacing-2) 0;
    overflow: hidden;
}

.icon {
    transition: transform 0.26s;
}
