@import "../../../../../../shared-components/css-modules/toyota/styles/mixins/mixins";
@import "../../../../../../shared-components/css-modules/toyota/utils/Animations.module";

.spec {
    --font-size: 1.2rem;
    --line-height: 2rem;

    &:not(:first-child) {
        margin-block-start: var(--spacing-2);
    }

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: auto auto;
        gap: var(--spacing-2);
        &:not(:first-child) {
            margin-block-start: calc(var(--spacing-1) / 2);
        }
        > *:nth-child(2) {
            text-align: end;
        }
    }
}

.subtitle {
    overflow: hidden;
    font-family: var(--font-semibold);
    text-overflow: ellipsis;
}

.price span {
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
    }
}

.spec-value {
    --color: var(--color-grey-7);
}

.tooltip {
    top: -0.2rem;
    margin-inline-start: 0;
}

.info {
    margin-inline-start: 2px;
}

.motorization {
    z-index: var(--motorization-z-index, 1);
    position: relative;
    margin-block-end: 30px; /* had to be custom here, the extra 2px didn't really work */
    padding-block-start: var(--spacing-2);
    animation: fadeIn 0.7s forwards ease-out;
    opacity: 0;
}

.collapse {
    margin-block-end: var(--spacing-2) !important; // Overwrite Collapse component.
    border: 1px solid var(--color-grey-4);
    border-radius: 4px;
    background: var(--color-primary-white);
    &.open {
        border: 1px solid var(--color-primary-black);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    }

    &.disabled {
        background: transparent;
        > summary {
            cursor: default;

            button {
                cursor: default;
            }

            > svg {
                color: var(--color-grey-4);
            }
        }
    }
    > summary {
        padding-inline-end: var(--spacing-2);
    }

    > div {
        z-index: calc(var(--motorization-z-index, 1) + 1);
        position: absolute;
        left: 0;
        inline-size: 100%;
        max-height: 400px;
        padding: 0;
        border-radius: 4px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        overflow-y: auto;
        @media (hover: hover) {
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px var(--color-grey-1);
                background-color: var(--color-primary-white);
            }

            &::-webkit-scrollbar {
                inline-size: 5px;
                background-color: var(--color-primary-white);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--color-grey-4);
            }
        }
    }
}

.eco {
    display: flex;
    justify-content: flex-end;
}

.efficiency {
    margin-inline-end: calc(var(--spacing-1) * -1);
    margin-block-start: var(--spacing-1);
}

.popoveraction {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    @include media-breakpoint-up(lg) {
        bottom: calc(var(--spacing-1) / 2);
    }
}

.engines-price {
    * {
        font-size: 1.2rem;
        line-height: 2.2rem;
        font-family: var(--font-book);
    }
}

button.bonus-eco {
    --color: var(--color-electrified-blue);
    margin-block-end: var(--spacing-2);
}
