.price {
    s {
        display: inline-block;
        text-decoration: line-through;
        font-style: normal;
        margin-inline-end: var(--spacing-1);
    }
}

.wrapper {
    display: flex;
    align-items: flex-end;

    .popover {
        margin-block-end: -2px;
    }
}

.column {
    display: flex;
    flex-direction: column;
}

.numbers {
    position: relative;
    top: -1px;
    display: inline-flex;
    gap: 4px;
    margin-inline-start: var(--spacing-1);
}

.number {
    display: block;
    inline-size: 2rem;
    block-size: 2rem;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: center;
    background-color: var(--color-grey-4);
    border-radius: 50%;
}
