@import "../../../../../../shared-components/css-modules/toyota/styles/mixins/mixins";

.wrapper {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-2);
    background-color: var(--color-grey-1);
    margin-block-end: var(--or-spacing-6);

    @include media-breakpoint-down(lg) {
        margin-block-start: var(--spacing-7);
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        gap: var(--spacing-2);
    }
}

.options {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-3);

    @include media-breakpoint-down(sm) {
        gap: var(--spacing-1);
    }
}

.option {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    gap: calc(var(--spacing-1) / 2);
}

.icon {
    margin-block-start: 3px;
}
