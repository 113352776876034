// Take over the screen reader class from ally because used as a check here.
.sr-only {
    composes: sr-only from "../utils/Accessibility.module.scss";
}

.default {
    --_spacing: var(--spacing, 0);

    position: relative;
    padding-inline-start: 3rem;
    margin-block-end: var(--_spacing);
    label {
        padding: 0;
        margin: 0;
        font-family: var(--font-book);
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 400;
        cursor: pointer;
        &::before {
            position: absolute;
            top: 0.3rem;
            inset-inline-start: 0;
            inline-size: 2rem;
            block-size: 2rem;
            border: 1px solid var(--color-grey-6);
            background-color: var(--color-primary-white);
            border-radius: 0.4rem;
            content: "";
            border-radius: 50%;
        }

        span {
            color: var(--color-grey-7);
        }
    }
    input[type="radio"] {
        position: absolute;
        border: 0px;
        clip: rect(0px, 0px, 0px, 0px);
        block-size: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0px;
        position: fixed;
        white-space: nowrap;
        inline-size: 1px;
        top: 0px;
        inset-inline-start: 0px;

        // Checked State
        &:checked + label {
            &::before {
                border-color: var(--color-primary-black);
            }
            &::after {
                position: absolute;
                top: 0.8rem;
                inset-inline-start: 0.5rem;
                inline-size: 1rem;
                block-size: 1rem;
                background-color: var(--color-primary-black);
                border-radius: 50%;
                content: "";
            }
        }
        &:focus-visible + label {
            &::before {
                outline: 2px solid var(--color-electrified-blue) !important;
            }
        }
        // Disabled State
        &:disabled {
            & + label {
                cursor: not-allowed;
                opacity: 0.5;
                &::before {
                    opacity: 0.5;
                }
            }
        }
    }
}
