.filter-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    gap: 1.1rem;
    padding: var(--spacing-2) var(--spacing-3);
    border-bottom: 1px solid var(--color-grey-4);
}

.filter-modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding-block: 0 var(--spacing-5);
    padding-inline: var(--spacing-3);
}

.filter-section {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    border-bottom: 1px solid var(--color-grey-4);
}

.filter-section-title {
    margin: 0;
    padding-block: var(--spacing-2);

    & > button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-family: var(--font-book);
        font-size: 1.6rem;
        line-height: 1.5;
    }

    button[aria-expanded="true"] {
        svg {
            transform: rotate(180deg);
        }
    }
}

.filter-section-content {
    &,
    li:nth-last-child(1) {
        padding-block-end: var(--spacing-2);
    }
}
