@import "../utils/Animations.module";
@import "../styles/mixins/mixins";

.modal {
    padding-block: var(--spacing-6);
    padding-inline: var(--spacing-5) var(--spacing-4);
    border: 0;
    background: var(--color-primary-white);
    inline-size: 80vw;
    max-inline-size: 960px;
    block-size: 90vh;
    block-size: 90dvh;
    opacity: 0;
    translate: 0 -4rem;
    @supports (transition-behavior: allow-discrete) {
        transition-property: opacity, translate, overlay, display;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
        transition-behavior: allow-discrete;
    }
    &::backdrop {
        opacity: 0;
        background-color: rgba(40, 40, 48, 0.75);
        @supports (transition-behavior: allow-discrete) {
            transition-property: opacity, overlay, display;
            transition-duration: 0.4s;
            transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
            transition-behavior: allow-discrete;
        }
    }

    &[open] {
        opacity: 1;
        translate: 0 0;
        @supports (transition-behavior: allow-discrete) {
            @starting-style {
                opacity: 0;
                translate: 0 -4rem;
            }
        }
        &::backdrop {
            opacity: 1;
            pointer-events: none;
            @supports (transition-behavior: allow-discrete) {
                @starting-style {
                    opacity: 0;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        inline-size: 100vw;
        block-size: 100vh;
        block-size: 100dvh;
        margin: 0;
        max-block-size: none;
        max-inline-size: unset;
    }
}

.close {
    position: absolute;
    inline-size: 4rem;
    block-size: 4rem;
    inset-inline-end: var(--spacing-3);
    inset-block-start: var(--spacing-3);
    z-index: var(--z-index-modal);
}

.default {
    composes: modal;
    inline-size: 80vw;
    block-size: 94vh;
    block-size: 94dvh;

    @include media-breakpoint-down(sm) {
        inline-size: 100vw;
        block-size: 100vh;
        block-size: 100dvh;
        margin: 0;
        max-block-size: none;
        max-inline-size: unset;
    }
}

.debug {
    block-size: 30vh;
    block-size: 30dvh;
}

.fullscreen {
    inline-size: 100%;
    max-inline-size: 100%;
}

.fullscreen-spin {
    block-size: 100%;
    max-block-size: calc(100% - calc(var(--spacing-4) * 2));
    inline-size: 100%;
    max-inline-size: calc(100% - calc(var(--spacing-4) * 2));
    padding-inline: 0;
    border-radius: 0.8rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    inset-block-start: var(--spacing-3);
}

.calculator {
    composes: default;
    padding-block-start: 0;
    padding-block-end: 0;

    // Wrapper inside calculator should take the full modal's height.
    > div {
        block-size: 100%;
    }

    .header {
        position: sticky;
        top: 0;
        block-size: var(--spacing-8);
        background: var(--color-primary-white);
        z-index: 10;
        .close {
            inset-inline-end: 0;
        }
    }
}

.large {
    composes: default;
    inline-size: 94vw;
    max-inline-size: var(--c-max-width);

    @include media-breakpoint-down(sm) {
        inline-size: 100%;
        max-inline-size: 100%;
    }
}

.compare {
    composes: large;

    .header {
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 0;
    }
}

.lcv-filter {
    padding: 0;

    .header {
        display: none;
    }
}

.filter {
    .header {
        display: none;
    }
}

.bottom {
    --border-radius: var(--spacing-1);

    block-size: calc(100vh - var(--toyota-header-height));
    inline-size: 100vw;
    max-inline-size: 100vw;
    margin-block-end: 0;
    padding: 0;
    border-start-start-radius: var(--border-radius);
    border-start-end-radius: var(--border-radius);
    overflow: hidden;
    bottom: 0;
    top: 0;
    opacity: 1;
    translate: 0 100%;
    @supports (transition-behavior: allow-discrete) {
        @starting-style {
            display: block;
            opacity: 1;
            translate: 0 100%;
        }
    }
    &[open] {
        opacity: 1;
        translate: 0 0;
        @supports (transition-behavior: allow-discrete) {
            @starting-style {
                opacity: 1;
                translate: 0 100%;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        block-size: 100dvh;
    }

    > div {
        overflow-y: auto;
        block-size: 100%;
    }

    .header {
        position: sticky;
        top: 0;
        padding: var(--spacing-3) var(--spacing-4);
        background: var(--color-primary-white);
        z-index: var(--z-index-modal);

        @include media-breakpoint-down(sm) {
            padding-inline: var(--spacing-2);
            block-size: var(--spacing-10);
        }

        .close {
            inset-block-start: var(--spacing-2);

            @include media-breakpoint-down(sm) {
                inset-block-start: var(--spacing-3);
            }
        }
    }

    .header-action {
        display: flex;
        align-items: center;
        gap: var(--spacing-1);

        .icon {
            top: 2px;
        }
    }
}

:global(.or-apheleia-summary-open.or-apheleia-modal-open .header) {
    z-index: -1;
}

:global(.or-apheleia-modal-open .header) {
    z-index: 0;
}

@supports selector(:has(+ *)) {
    body:has(.modal[open]) {
        overflow-y: hidden;
    }
}
