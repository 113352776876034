.primary-black {
    --color: var(--color-primary-black);
}
.highlight-black {
    --color: var(--color-highlight-black);
}
.primary-white {
    --color: var(--color-primary-white);
}
.primary-red {
    --color: var(--color-primary-red);
}
.highlight-red {
    --color: var(--color-highlight-red);
}
.electrified-blue {
    --color: var(--color-electrified-blue);
}
.green {
    --color: var(--color-green);
}
.grey-1 {
    --color: var(--color-grey-1);
}
.grey-4 {
    --color: var(--color-grey-4);
}
.grey-6 {
    --color: var(--color-grey-6);
}
.grey-7 {
    --color: var(--color-grey-7);
}
