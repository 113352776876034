.default {
    --_color: var(--color, var(--color-primary-black));
    --_spacing: var(--spacing, 0);

    margin-block: 0 var(--_spacing);
}

.checkmarks {
    composes: default;

    margin-inline: 0;
    padding-inline: 0;
    list-style: none;

    li {
        position: relative;
        color: var(--_color);
        padding-inline-start: var(--spacing-4);

        &::before {
            --svg: url("data:image/svg+xml,%3Csvg viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0.90918L4.5 11.4092L1 7.90918' stroke='%23000000'/%3E%3C/svg%3E%0A");

            position: absolute;
            top: calc(var(--spacing-1) / 2);
            inset-inline-start: 0;
            display: block;
            inline-size: 2rem;
            block-size: 2rem;
            background-color: var(--icon-color, var(--_color));
            background-image: var(--svg);
            -webkit-mask: var(--svg);
            ///* Firefox and Safari */
            mask: var(--svg);
            content: "";
        }
    }

    &.sm {
        li {
            padding-inline-start: var(--spacing-3);

            &::before {
                --svg: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0.90918L4.5 11.4092L1 7.90918' stroke='%23000000'/%3E%3C/svg%3E%0A");

                top: 0.3rem;
                inline-size: 1.3rem;
                block-size: 1.3rem;
            }
        }
    }
}

.checkmarks-reverse {
    composes: checkmarks;

    li {
        padding-inline-start: 0;
        padding-inline-end: var(--spacing-4);
        &::before {
            --icon-color: var(--color-green);
            inset-inline: auto 0;
        }
    }
    &.sm {
        li {
            padding-inline-start: 0;
            padding-inline-end: var(--spacing-3);

            &::before {
                --icon-color: var(--color-green);
                background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0.90918L4.5 11.4092L1 7.90918' stroke='%234CAF50'/%3E%3C/svg%3E%0A");
                webkit-mask: none;
                ///* Firefox and Safari */
                mask: none;
                background-color: transparent;
                top: 0.3rem;
                inline-size: 1.3rem;
                block-size: 1.3rem;
            }
        }
    }
}

.sm {
    font-size: 1.2rem;
    line-height: 1.7rem;

    li {
        margin-block-end: var(--spacing-1);
    }
}
