@import "../styles/mixins/mixins";

.swatch {
    display: flex;
    align-items: center;
    justify-content: center;
}

.default {
    --_background: var(--color, transparent);
    --_swatch-size: var(--swatch-size, 4.4rem);

    position: relative;
    inline-size: var(--_swatch-size);
    block-size: var(--_swatch-size);
    background: var(--_background);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
        --_swatch-size: var(--swatch-size, 5.6rem);
    }
    &::after {
        --offset: calc(var(--_swatch-size) + var(--spacing-1) + 2px);

        position: absolute;
        top: 50%;
        left: 50%;
        inline-size: var(--offset);
        block-size: var(--offset);
        border: 2px solid var(--ring-color, transparent);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: border-color var(--default-transition-time) ease-out;
        content: "";
    }
    img {
        border-radius: 50%;
        max-block-size: 100%;
        max-inline-size: 100%;
        aspect-ratio: 1;
        object-fit: cover;
    }
}

.active {
    position: relative;
    --ring-color: var(--color-primary-red);
    button:focus-visible & {
        --ring-color: var(--color-electrified-blue);
    }
    :global(.bz) & {
        --ring-color: var(--color-bz4x-hover);
    }
}

.md {
    composes: default;
    --swatch-size: 2.4rem;
    &.active::after {
        --offset: calc(var(--_swatch-size) + 8px);
    }
}

.sm {
    composes: default;
    --swatch-size: 1rem;
    &.active::after {
        --offset: calc(var(--_swatch-size) + 4px);

        border: 1px solid var(--color-primary-black);
    }
}

.has-gradient {
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        inset-block: 0;
        inset-inline: 0;
        border-radius: 50%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.33) 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
        content: "";
    }
}
