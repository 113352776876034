.default {
    --_spacing: var(--spacing, 0);

    display: inline-block;
    padding: 4px;
    margin-block: 0 var(--_spacing);
    font-family: var(--font-book);
    font-size: var(--font-size, 1.4rem);
    line-height: var(--line-height, 1.8rem);
    color: var(--color-electrified-blue);
    background-color: var(--color-primary-white);
    > * {
        margin-inline-end: 0.6rem;
    }
    .icon {
        position: relative;
        top: 0.1rem;
        display: inline-block;
        inline-size: 1.4rem;
        block-size: 1.9rem;
        color: inherit;
    }

    .drop-icon {
        inline-size: 2rem;
        block-size: 2rem;
    }

    .droplet-icon {
        inline-size: 1.1rem;
        block-size: 1.4rem;
    }
}

.hybrid,
.plugin,
.electric,
.fuelCell,
.petrol,
.diesel,
.multi {
    composes: default;
    display: inline-flex;
}

.clean {
    padding: 0;
    background-color: transparent;
}
