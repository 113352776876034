@import "../../../../../shared-components/css-modules/toyota/styles/mixins/mixins";

.panel {
    --scroll-margin: var(--spacing-3);
    --gap: var(--spacing-2);
    /* take 100% width and substract the container max width. add the padding of the default container and devide this calculation by 2.*/
    --dummy-width: calc(
        ((100% - (min(var(--c-max-width), 100%) + (var(--c-padding) * 2))) / 2) + (var(--c-padding) * 2)
    );

    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    align-items: stretch;
    justify-content: start;
    gap: var(--gap);
    margin-block-start: var(--spacing-3);
    margin-block-end: var(--spacing-3);
    padding-inline: var(--dummy-width);
    padding-bottom: var(--spacing-10);
    scroll-padding-inline: var(--dummy-width);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: var(--c-max-width);
    &::-webkit-scrollbar-track {
        --width: calc(100vw - (var(--scrollbar-size, 0) * 1px));
        -webkit-box-shadow: inset 0 0 6px var(--color-grey-4);
        /* Below code: Same as dummy width, but 100% width of the custom property could not be inherited in this pseudo element.
         So had to do this again but instead of using 100%, using a 100vw minus a scrollbar width calculated by JS passed as a custom property from Selector.tsx
         */
        margin-inline: calc(
            ((var(--width) - (min(var(--c-max-width), var(--width)) + (var(--c-padding) * 2))) / 2) +
                (var(--c-padding) * 2)
        );
        background-image: linear-gradient(
            to bottom,
            transparent 0%,
            transparent 11px,
            var(--color-grey-4) 11px,
            var(--color-grey-4) 13px,
            transparent 13px,
            transparent 100%
        );
        box-shadow: none;

        @include media-breakpoint-down(xs) {
            margin-inline: var(--spacing-3);
        }
    }

    @include media-breakpoint-up(lg) {
        margin-block-start: var(--spacing-6);
        margin-block-end: var(--spacing-6);
        padding-block-end: var(--spacing-5);
    }

    &::-webkit-scrollbar {
        block-size: 24px;
    }

    &::-webkit-scrollbar-thumb {
        background-image: linear-gradient(
            to bottom,
            transparent 0%,
            transparent 11px,
            var(--color-highlight-black) 11px,
            var(--color-highlight-black) 13px,
            transparent 13px,
            transparent 100%
        );
    }

    &.dragging {
        @media (hover: hover) {
            scroll-snap-type: none;
            cursor: ew-resize;
            scroll-behavior: auto;
            user-select: none;
        }
    }
    img {
        pointer-events: none;
    }
}

.navigation {
    display: flex;
    justify-content: space-between;
    margin-block-start: var(--spacing-3);
    margin-block-end: var(--spacing-2);

    @include media-breakpoint-up(lg) {
        margin-block-start: var(--spacing-4);
        margin-block-end: var(--spacing-4);
    }
}

.buttons {
    display: flex;
    gap: var(--spacing-1);

    @include media-breakpoint-up(md) {
        &.hidden {
            display: none;
        }
    }

    &.force-hide {
        display: none;
    }
}

.mobile-slider-buttons {
    @include media-breakpoint-up(lg) {
        display: none;
    }

    display: flex;
    justify-content: flex-end;
    inline-size: 100%;
    margin-inline: auto;
    max-inline-size: var(--c-max-width);
    padding-inline: var(--c-padding);
    // As this should be aligned correctly with the scrollbar below and the slider on top, and because the scrollbar can defer depending on the browser
    // a fine tuned value needed to be used here. Didn't really find a way to do this differently.
    margin-block-start: calc(var(--spacing-6) * -2);
}

.slider-dummy {
    flex: 0 0 calc(var(--dummy-width) - var(--gap));
    scroll-snap-align: end;
    @include media-breakpoint-down(xs) {
        display: none;
        + article {
            margin-inline-start: var(--c-padding);
        }

        ~ article:nth-last-child(2) {
            margin-inline-end: var(--c-padding);
        }
    }
}
